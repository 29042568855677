
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Component, Emit, Watch } from 'vue-property-decorator';
import { Range } from '@/interfaces/components/Range';
import { OrderFlavor } from '@/enums/OrderFlovor';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '@/mixins/StackedForm';

@Component({
  components: { VFormBuilder },
})
export default class SalesOverviewFilter extends mixins(CustomerGroup, StackedForm) {
  public $refs!: {
    customerGroupForm: InstanceType<typeof VFormBuilder> & { form: any };
    dateForm: InstanceType<typeof VFormBuilder> & { form: any };
  };
  public customerGroup: string | null = null;
  public range: Range = {
    from: '',
    to: '',
  };

  get customerGroupItems() {
    return [
      {
        name: 'customerGroup',
        type: InputType.Autocomplete,
        label: 'billing.form.customerGroup',
        items: this.customerGroups,
        default: null,
        rules: 'required',
        change: (value: string) => (this.customerGroup = value),
      },
    ];
  }

  get dateItems() {
    return [
      {
        name: 'range',
        type: InputType.DateRangeOneField,
        label: 'billing.form.range',
        rules: 'required',
        showTimeSelector: false,
        change: (range: { from: string; to: string }) => (this.range = range),
      },
    ];
  }

  get flavors(): string[] {
    return Object.values(OrderFlavor);
  }

  get selection() {
    return {
      customerGroup: this.customerGroup,
      ...this.range,
    };
  }

  public onChangeRange(event: { from: string; to: string }) {
    this.range.from = event.from;
    this.range.to = event.to;
  }

  public async validateFilter() {
    const results = await Promise.all(await this.validateWithKeys());
    const isValidWithKey: any = this.isValidWithKey(results);
    return isValidWithKey;
  }

  @Watch('selection')
  @Emit('search')
  public onSearch() {
    return this.selection;
  }

  @Emit('change')
  public reset() {
    return this.selection;
  }
}
