
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SalesTable from '@/components/sales/SalesTable.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import { SalesOverview as SalesItem } from '@/interfaces/models/SalesOverview';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import Filter from '@/interfaces/api/Filter';
import SalesOverviewTable from '@/components/salesOverview/SalesOverviewTable.vue';
import SalesOverviewFilter from '@/components/salesOverview/SalesOverviewFilter.vue';
import SalesOverviewLoader from '@/components/salesOverview/SalesOverviewLoader.vue';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import moment from 'moment';

const salesOverview = namespace('salesOverview');

@Component({
  components: { VRangePicker, SalesOverviewLoader, SalesOverviewFilter, SalesOverviewTable, VWrapper },
})
export default class SalesOverview extends mixins(Notification) {
  public $refs!: {
    table: SalesTable & { resetPage: () => void };
    filter: InstanceType<typeof SalesOverviewFilter> & {
      validateFilter: any;
    };
  };

  @salesOverview.State('items') public items!: SalesItem[];
  @salesOverview.State('filter') public filter!: Filter;
  @salesOverview.State((state) => state.pagination.total) public total!: number;
  @salesOverview.Action('fetch') public getSales: any;
  @salesOverview.Action('setFilter') public setFilter: any;
  @salesOverview.Action('reset') public reset: any;

  public beforeMount() {
    this.reset();
  }

  public async onSearch(queries: { customerGroup: string; from: string; to: string }) {
    if (!this.isValidSearchQuery(queries)) return;

    try {
      this.$startLoading('salesOverview');
      this.setFilter(queries);
      await this.getSales();
    } finally {
      this.$stopLoading('salesOverview');
    }
  }

  private isValidSearchQuery(queries: { customerGroup: string; from: string; to: string }): boolean {
    const { customerGroup, from, to } = queries;
    return Boolean(customerGroup) && (Boolean(from) || Boolean(to));
  }

  public async exportSales() {
    const results = await this.$refs.filter.validateFilter();
    if (results.result) {
      this.$startLoading('exportSales');
      const api: OrderServiceApi = new OrderServiceApi();

      api
        .salesOverview({ ...(this.filter as { customerGroup: string; from: string; to: string }) })
        .then((res: AxiosResponse<string>) => {
          toUrl(res.data, `sales-${moment().format('DD-MM-YYYY')}.xlsx`);
          this.notifyInfo('notification.downloadInProgress');
        })
        .finally(() => {
          this.$stopLoading('exportSales');
        });
    }
  }
}
